<template>
    <v-dialog
        transition="dialog-bottom-transition"
        
        v-model="show"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="orange darken-3"
                dark
                class="text-h5"
            >Anteprima Orari {{selectedMonth.setLocale('it').toFormat('MMMM yyyy')}}
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text style="overflow-y: auto; height: 500px;">
                <v-container fluid v-if="!monthPreviewLoading">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                    </th>
                                    <th class="text-center">
                                        ORE TIMBRATE
                                    </th>
                                    <th class="text-center">
                                        ORE PERMESSO
                                    </th>
                                    <th class="text-center">
                                        FERIE
                                    </th>
                                    <th class="text-center">
                                        ORE STRAORDINARIO
                                    </th>
                                    <th class="text-center">
                                        TRASFERTA
                                    </th>
                                    <th class="text-center">
                                        MALATTIA
                                    </th>
                                    <th class="text-center">
                                        TURNO
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="n in selectedMonth.endOf('month').get('day')"
                                    :key="n" 
                                    :set="dateData = findWorkDateData(n)"
                                >
                                    <td class="text-right">
                                        {{getDateOfMonth(n).setLocale('it').toFormat('cccc d')}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.punchedTimeValue > 0 ? dateData.punchedTimeValue : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.leaveTimeValue > 0 ? dateData.leaveTimeValue : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.holidayTimeValue > 0 ? dateData.holidayTimeValue : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.overtimeTimeValue > 0 ? dateData.overtimeTimeValue : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.businessTripDayValue ? 'T' : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.sickDayValue ? 'M' : ''}}
                                    </td>
                                    <td class="text-center">
                                        {{dateData && dateData.availabilityDayValue ? 'R' : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-container>
                <v-progress-linear
                    v-else
                    indeterminate
                    color="orange darken-3"
                ></v-progress-linear>
            </v-card-text>
        </v-card>  
    </v-dialog>
</template>
<script>
import { DateTime } from 'luxon';

export default {
    data: function() {
        return {
        }
    },
    props: {
        userWorkingHoursData: {
            type: Object,
            default: function() {
                return {};
            }
        },
        monthPreviewLoading: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            required: true
        },
        selectedMonth: {
            type: Object,
            required: true
        }
    },
    methods: {
        cancelMethod: function() {
            this.$emit('close-month-working-hours-preview-dialog');
        },
        getDateOfMonth: function(day) {
            return DateTime.fromObject({day: day, month: this.selectedMonth.get("month"), year: this.selectedMonth.get("year")});
        },
        findWorkDateData: function(day) {
            console.log()
            return this.userWorkingHoursData.workingHoursDaysData.find(d => DateTime.fromISO(d.workDate).toISODate() == this.getDateOfMonth(day).toISODate());
        }
    },
    computed: {
    }
}
</script>
<style scoped>
    .scroller {
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>